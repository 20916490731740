header {
    display: flex;
    width: 100%;
    color: var(--text-color);;
    justify-content: space-evenly;
    height: 10vh;
    position: fixed;
    top: 0;
    align-items: center;
    z-index: 30;
}

header nav li a, header h1 a {
    color: var(--text-color);
    text-decoration: none;
    -webkit-text-decoration: none;
    text-decoration-line: none;
    -webkit-text-decoration-line: none;
    filter: drop-shadow(0.05em 0.05em 0.5em var(--background-color));  

} 

header::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background: var(--background-color);
    opacity: 0.9;
    mask: linear-gradient(to top, transparent, var(--background-color) 20%);

    fill: linear-gradient(to top, transparent, var(--background-color) 20%);
    backdrop-filter: blur(2px);
    transition: background-color 0.2s ease-in-out;
 }

 header nav {
    display: flex;
    align-self: center;
    font-family: 'Poppins';
} 

header nav li {
    list-style-type: none;
    padding: 0 1em;
    text-decoration: underline;
    -webkit-text-decoration: underline;
    color: transparent;
}
header nav li a {
    color: var(--text-color);
}
header nav li:hover {
    color: var(--text-color);
}
.active-link {
    text-decoration: underline;
    -webkit-text-decoration: underline;
    color: var(--text-color);
}

.dark-mode-switch {
    color: var(--text-color);
    cursor: pointer;
    width: 1.2em;
    scale: 1.2;

}

header h1 a {
    align-self: center;
    user-select: none;
    cursor: pointer;
}
#about {
    cursor:pointer;
} 
.logo-container {
    height: 10vh;
    width: 185px;
    margin-left: 1em;

}
.logo {
    position: absolute;
    top: 0;
    height: 100%;
    margin-left: 1em;

}
.logo * {
    display:flex;
    align-items: center;
    font-family: 'Libre Bodoni';
    font-size: 2rem;
    scale: 1;
    color: var(--text-color);;
}
.logo-text-container {
    height: 10vh;

}
.initial-e, .initial-j {
    z-index: 3
}
.logo-hide-uneau, .logo-hide-ve {
    clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
    position: relative;
    opacity: 0;
    z-index: 1;
}

.logo-container {
    margin-left: 0em;
}

.logo-container:hover {
    .logo {
        margin-left: 0;
    }

    * {
        scale: 1;
    }
    .logo-hide-uneau, .logo-hide-ve {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        opacity: 1;
        transition: clip-path 0.5s ease-in-out opacity 0.5s ease-in-out;
    }

    .initial-e {
        margin-right: 0;
    }
    .initial-j {
        margin-left: 0rem;
    }


}
.initial-e {
    margin-right: -0.65em;
}
.initial-j {
    margin-left: -0.7em;
}

@media (max-width: 767px) {


    header {
        justify-content: space-between;
    }
    .logo {
        left: 2em;
    }
    .hamburger {
        position: absolute;
        right: 0;
        padding: 2em;
    }

}


/* @keyframes mouseonclip {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes mouseoutclip {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
} */
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
