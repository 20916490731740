#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App {
  color: var(--text-color);;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* transition: background-color 0.2s ease; */
  flex-direction: column;
  margin-top: 10vh;
  width: clamp(40%, 300px, 80%);
}
.App .Portfolio {
  padding-left: 0;
}
.App .Portfolio li {
  list-style-type: none;
  text-align: center;
  padding: 0.6vh 0;
  margin: 0.8em 0;
}
.list-item {
  text-decoration: underline;
  -webkit-text-decoration: underline;
  color: transparent;
  text-decoration-thickness: 0.15rem;
  text-underline-offset: 5px;
}
.list-item a {
  color: var(--text-color);
  font-size: 3rem;
  text-decoration: none;
  }
.emoji-wave {
  animation-name: wave;
  animation-delay: 1.5s;
  animation-duration: 1.5s;
  margin-right: 0.2em;
}
@keyframes wave {
  0% {rotate: 0deg;}
  20% {rotate: 30deg;}
  21% {rotate: 28deg; }
  58% {rotate: -8deg; }
  70% {rotate: 0deg;}
}
.App a {
  color: var(--text-color);
}
.App h2, .App p {
  text-align: center;
}

@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}
.portfolio, .contact, .Portfolio, .About, .Project, .project-container, .desktop-portfolio-item, .mobile-portfolio-item, .App, #recaptcha {
  animation-name: fadein;
  animation-duration: 0.5s;
}

.intro {
  display: flex;
  width: 100%;
  justify-content: center;
  a {
    color: var(--text-color);;
    text-align: center;
  }
  img {
    border-radius: 50%;
    width: 100%;
  }
  .left-column {
    width: 30vw;
    margin-right: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    h1 {
      text-align: right;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .right-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20vw;
    margin-left: 1em;
  }
}

@media (max-width: 767px) {
  .App {
    width: 90%;
  }
  .intro {
    .left-column {
      width: 50vw;
      text-align: center;
      h1 {
        text-align: center;
      }
    }
    .right-column {
      width: 40vw;
    }
  }


}