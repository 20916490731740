.NotFound {
  margin-top: 10vh;
  height: 80vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NotFound a {
  color: white;
  font-family: 'Poppins';

}