.Footer {
    display: flex;
    width: 100%;
    color: var(--text-color);
    justify-content: space-evenly;
    position: relative;
    bottom: 0;
    height: 10vh;
    transition: background-color 0.2s ease;
    overflow: hidden;
}

.Footer::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
 
    background: var(--background-color);
    opacity: 0.7;
    mask: linear-gradient(to bottom, transparent, black 50%);
    backdrop-filter: blur(2px);
    transition: all 0.2s ease-in-out
 }

.Footer .disclaimer {
    font-size: 0.8rem;
    text-align: center;
    align-self: center;
}