
* {
  outline: 0px solid red;   
  transition: all .2s ease-in-out, font-size 0s;}
@font-face {
  font-family: 'Libre Bodoni';
  src: url(./Assets/Libre-Bodoni-Regular.ttf);
}
@font-face {
  font-family: 'Poppins';
  src: url(./Assets/Poppins-Regular.ttf);
}
h1, h2, h3, h4, h5, h6 {
  font-family:'Libre Bodoni', Times, serif;
  font-weight: 400;
}
p {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
html {
  font-size: calc(12.5px + 0.4vw);
  background-color: var(--background-color);
  --off-white: #eee7d7;
  --off-black: #0F0F0F;

  --dark-filter: invert(100%);
  --light-filter: invert(0%);

  --dark-mode-opacity: 0.7;
  --light-mode-opacity: 1.0;
  --image-opacity: var(--light-mode-opacity)

}

img{
  opacity: var(--image-opacity)
}

