.project-container {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  padding: 0 20vw;

}
.project-container * {
  color: var(--text-color);
}
.project-image {
  width: 60%;
  height: calc(60vw * 9 / 16);
  margin-bottom: 3em;
  margin-top: 10vh;
  object-fit: contain;
  position: fixed;
  top: 0;
}

.project-cover {
  z-index: 5;
  width: 50vw;
  padding: 0 25vw;
  background: rgb(12,12,12);
  background: linear-gradient(0deg, var(--background-color) 70%, rgba(15,15,15,0) 100%);
  h1 {
    filter: drop-shadow(0.05em 0.05em 0.5em var(--background-color));  }
}

.project-showreel, .project-showreel * {
  user-select: none;
  -moz-user-select: none;
  pointer-events: none;
}
.showreel-container, .image-container {
  height: calc(60vw * 9 / 16);
  width: 60vw;
  position: relative;
  margin-bottom: 3em;
}

.ytp-chrome-top.ytp-show-cards-title, .ytp-paid-content-overlay, .ytp-pause-overlay-container, .ytp-watermark {
  display: none !important;
}
.back svg {
  margin-right: 5px;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);

}
.back {
  /* re-implement this later!*/
  position: fixed;
  display: none;
  left: 10vw;
  bottom: 10vh;
  align-self: flex-start;
}

.back:hover svg, .forward:hover svg {
  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
}

.forward svg {
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
} 

.project-cover a {
  color: transparent
}
.project-cover a p {
  text-decoration: underline;
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}
.project-cover a:hover p {
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
}

.project-cover a:hover {
  color: var(--text-color)
}

@media(max-width: 767px) {
  .project-container {
    padding: 0 5vw;
    margin-top: 0vh;
    width: 90vw;
    overflow-x: hidden;
  }

 .showreel-container > iframe {
    width: 100vw !important; 
    height: calc(100vw * 9 / 16) !important;
    margin-left: -20vw;
  }

  .image-container {
    width: 100vw;
    height: calc(100vw * 9 / 16);
  }
  .project-image {
    width: 100vw;
    height: calc(100vw * 9 / 16);

  }
}