body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "libre bodoni"
}
p, a, li {
  font-family: "Poppins";
}

a {
  user-select: none;
  -moz-user-select: none;
}
