.AboutContainer {
    /* background-color: var(--background-color); */
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    margin-top: 10vh;
}
.About {
    align-self: center;
    padding:  0 0;
    width: clamp(40%, 300px, 80%);
    text-align: center;
}
a.About {
    color: var(--text-color);
}
.About a {
    color: var(--text-color);
}


/* Album showcase */

.AlbumShowcaseContainer {
    width: clamp(40%, 300px, 80%);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    img, h1 {
        width: 60%;
        text-align: center;
    }
    .music-button, .music-button a {
        background-color: var(--text-color);
        color: var(--background-color);
    }
    .music-button:hover {
        opacity: 0.7;
    }
    .music-button {
        margin: 0 1em;
        border-radius: 5px;

        padding: 0 1em;
        min-width: 145px;
    }
    .music-button a { 
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        padding: 0.5em 0;
        p {
            margin-left: 0.5em;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

@media (max-width: 767px) {
  .links-button-container {
    flex-direction: column;
  }
  .AlbumShowcaseContainer {
    .music-button {
        margin-top: 0.9em;
    };
    .available-here {
        margin-bottom: 0;
        margin-top: 0.9em;
    }
}
}

