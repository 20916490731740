
.portfolio {
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-top: 10vh;
}
.portfolio-list {
    display: flex;
    align-items: flex-start;
    width: 100vw;
    justify-content: space-evenly;
    flex-wrap:wrap;
}

.portfolio-item-container {
    text-decoration: none;
    color: var(--text-color);
    position: relative;
}
.portfolio-item h3 {
    font-size: 1.4rem;
    text-align: center;
}
.portfolio-item {
    margin: 3rem 2rem;
    filter: blur(0px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0s;
    animation-name: fadein;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.portfolio-item-button {
    color: var(--text-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--text-color);
    padding: 0.5em 1em;
    border-radius: 5px;
    svg, p {
        color: var(--background-color);
        margin: 0

    }
    svg {
        margin-right: 0.2em;
    }
    p {
        margin-left: 0.2em;
    }
}

.portfolio-item-button:hover {
    opacity: 0.3;
}

.desktop-portfolio-item {
    animation-name: fadeoutin;
    animation-duration: 0.5s;
}
.mobile-portfolio-item {
    animation-name: fadeinout;
    animation-duration: 0.5s;
}
.portfolio-item img {
    width: 100%;

    position: absolute;

}
.Portfolio a {
    font-family: "libre bodoni"
}
.portfolio-item h3 { margin: 0}
.portfolio-item p, .portfolio-item h3, .portfolio-item a {
    opacity: 0;
    /* color: var(--text-color); */
    /* margin: 0; */
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select:none;
    scale: .9;
    text-align: center;
    filter: blur(30px);

}
.mode-toggle {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 5%;
    min-width: 5rem;
    user-select: none;
    cursor:pointer;
    padding: 0.5rem;
}
.arrows.desktop {
    animation-name: changemodetodesktop;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}
.arrows.mobile {
    animation-name: changemodetomobile;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}
.arrows {
    height: 1rem;
    width: 1rem;
}
.arrows:hover {
    scale: 0.9;
}

.chevron {
    height: 6%; 
    position: absolute;
    top: 47%;
    bottom: 47%;


}
.chevron-left {
    left: 8.5%;
}
.chevron-right {
    right: 8.5%;
}


#draggable > * {
    scroll-snap-align:center;
}
@keyframes fadeoutin {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeinout {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes changemodetomobile {
    0% {
        transform:  rotate(0);
        opacity: 0;
    }
    100% {
        transform: rotate(-180deg);
        opacity: 1;
    }

}
@keyframes changemodetodesktop {
    0% {
        transform: rotate(0);
        opacity: 0;
    }
    100% {
        transform: rotate(180deg);
        opacity: 1;
    }
}
@media (min-width: 767px) {
    .portfolio-item:hover img {
        scale: 105%;
        filter: blur(5px);
        opacity: 0.2;
    }
    .portfolio-item:hover p, .portfolio-item:hover h3, .portfolio-item:hover a, .undisclosed-games p, .undisclosed-games h3 {
        opacity: 1;
        scale: 1;
        filter: blur(0px);
        z-index: 3;
    }
    .portfolio-item:hover a:hover {
        opacity: 0.7
    }
}

@media (max-width: 767px) {

    .portfolio-list {
        width: 100vw;
        scroll-snap-type: x mandatory; 
        overflow-x: hidden;
    }
    .mobile-portfolio-item {
        scroll-snap-align: center;

    }
    .portfolio-item div {
        width: 70%;
    }
    .portfolio-item img {
        width: 70%;
    
        position: absolute;
    
    }
    .portfolio-item.active p, .portfolio-item.active h3, .portfolio-item.active a {
        opacity: 1;
        scale: 1;
        filter: blur(0px);
        z-index: 3;
    }
    .portfolio-item.active img {
        scale: 105%;
        filter: blur(5px);
        opacity: 0.2;
    }
    .undisclosed-games > div {
        width: 100%;
    }
}

